


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {price, required} from '../../../utils/validationRules';
import {namespace} from 'vuex-class';

const user = namespace('user');

@Component({name: 'TkwCostInputs'})
export default class TkwCostInputs extends Vue {
  @Prop()
  loading!: boolean

  @Prop()
  currencies!: string[]

  @Prop()
  values!: {}

  @user.Getter('getCompanyCurrency')
  companyCurrency!: string

  changeValue(value: string, currency: string) {
    if (value === '') {
      // @ts-ignore
      this.values[currency] = undefined;
    }
  }

  getCostRules(currency: string) {
    const rules = [price]
    if (currency === this.companyCurrency) {
      return [...rules, required]
    }
    return rules
  }
}

import axios from 'axios';
import {ITkwDetailsItem} from '@/types/tkw';

export default class TkwRepository {
  static getAll() {
    return axios.get('tkw/all')
  }

  static getHistory(sku: string) {
    return axios.get(`tkw/history/${sku}`)
  }

  static saveOrUpdateTkw(tkw: ITkwDetailsItem) {
    return axios.post(`tkw`, tkw)
  }

  static remove({sku, year, month, day}: {sku: string, year: string, month: string, day: string}) {
    return axios.delete(`tkw/${sku}/${year}/${month}/${day}`)
  }

  static importCosts(file: FormData) {
    return axios.post('tkw/import', file)
  }

  static downloadCostsForm({year, month, day}: {year: string, month: string, day: string}) {
    return axios.get(`/tkw/export/${year}/${month}/${day}`, {responseType: 'blob'})
  }

  static getForms() {
    return axios.get('tkw/forms')
  }
}






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'FileUploadButton'
})
export default class FileUploadButton extends Vue {
  @Prop()
  accept!: string

  isSelecting: boolean = false

  onButtonClick() {
    this.isSelecting = true
    window.addEventListener('focus', () => {
      this.isSelecting = false
    }, { once: true })

    // @ts-ignore
    this.$refs.uploader.click()
  }

  onFileChanged(e: any) {
    this.$emit('change', e.target.files[0])
    // @ts-ignore
    this.$refs.uploader.value = ''
  }
}








































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {ITkwDetailsItem} from '@/types/tkw';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

@Component({
  name: 'TkwDetailsItem'
})
export default class TkwDetailsItem extends Vue {
  @Prop()
  data!: ITkwDetailsItem

  @Emit('edit')
  edit() {
    return this.data
  }

  @Emit('remove')
  remove() {
    return this.data
  }

  formatNumber(num: number) {
    if (num) {
      return formatNumberWithSpaces(num, 2)
    }
    return formatNumberWithSpaces(0, 2)
  }
}

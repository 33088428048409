





































































































import {Component, Vue} from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import TkwService from '@/services/TkwService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import Loader from '@/components/Loader.vue';

@Component({
  name: 'TkwImport',
  components: {Loader, FileUploadButton, DatePicker}
})
export default class TkwImport extends Vue {
  fileUploading: boolean = false
  fileDownload: boolean = false
  step: number = 1

  date: string = ''


  async downloadFile() {
    this.fileDownload = true
    try {
      await TkwService.downloadCostsForm(this.date)
    } catch (e) {
      resolveError(e, 'download')
    } finally {
      this.fileDownload = false
    }
  }

  async uploadFile(file: File) {
    this.fileUploading = true
    try {
      await TkwService.importCosts(file)
      notify(NotificationTypes.success, 'File uploaded')
      this.$emit('fileUploaded')
    } catch (e) {
      resolveError(e, 'upload')
    } finally {
      this.fileUploading = false
    }
  }
}

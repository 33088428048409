
























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ITkwDetailsItem, ITkwItem} from '@/types/tkw';
import {resolveError} from '@/utils/notifications';
import TkwService from '@/services/TkwService';
import TkwDetailsItem from '@/components/Tkw/TkwDetailsItem.vue';
import TkwForm from '@/components/Tkw/Form/TkwForm.vue';
import TkwEditCost from '@/components/Tkw/Form/TkwEditCost.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import Loader from '@/components/Loader.vue';

@Component({
  name: 'TkwDetails',
  components: {Loader, ConfirmationDialog, TkwEditCost, TkwForm, TkwDetailsItem}
})
export default class TkwDetails extends Vue {
  @Prop()
  tkw!: ITkwItem

  fetchLoading: boolean = false
  removeTkwLoading: boolean = false

  tkwToRemove: ITkwDetailsItem | null = null

  actualCosts: ITkwDetailsItem[] = []
  historyCosts: ITkwDetailsItem[] = []

  editCost: ITkwDetailsItem | null = null

  @Watch('tkw')
  onTkwChange(value: ITkwItem | null) {
    if (value) {
      this.fetchTkwDetails()
    }
  }

  setTkwToRemove(item: ITkwDetailsItem) {
    this.tkwToRemove = item
  }

  setEditCost(item: ITkwDetailsItem) {
    this.editCost = item
  }

  updateTkwCost() {
    this.fetchTkwDetails()
    this.$emit('refreshList')
  }

  async removeTkw() {
    this.removeTkwLoading = true
    try {
      await TkwService.remove(this.tkwToRemove!)
      await this.fetchTkwDetails()
      this.$emit('refreshList', !this.actualCosts.length)
      this.tkwToRemove = null
    } catch (e) {
      resolveError(e, 'delete')
    } finally {
      this.removeTkwLoading = false
    }
  }

  async fetchTkwDetails() {
    this.fetchLoading = true
    try {
      const costs = await TkwService.getDetails(this.tkw.sku)
      const sortedCosts = costs.sort((a: ITkwDetailsItem, b: ITkwDetailsItem) =>
          (a.dateFrom < b.dateFrom) ? 1 : ((b.dateFrom < a.dateFrom) ? -1 : 0)
      )
      if (sortedCosts.length) {
        this.actualCosts = [sortedCosts.shift()]
        this.historyCosts = sortedCosts
      } else {
        this.actualCosts = []
        this.historyCosts = []
      }
    } catch (e) {
      resolveError(e, 'fetch_details')
    } finally {
      this.fetchLoading = false
    }
  }

}

import TkwRepository from '@/repositories/TkwRepository';
import TkwFactory from '@/factories/TkwFactory';
import FileService from '@/services/FileService';
import {ITkwDetailsItem} from '@/types/tkw';
import store from '@/store';

export default class TkwService {
    static async getAll() {
        const {data} = await TkwRepository.getAll()

        return data.map((item: any) => TkwFactory.toSku(item))
    }

    static async getDetails(sku: string) {
        const {data} = await TkwRepository.getHistory(sku)

        return data.history
    }

    static saveOrUpdateTkw(tkw: ITkwDetailsItem) {
        return TkwRepository.saveOrUpdateTkw(tkw)
    }

    static remove(tkw: ITkwDetailsItem) {
        const [year, month, day] = tkw.dateFrom.split('-')
        return TkwRepository.remove({sku: tkw.sku, year, month, day})
    }

    static importCosts(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        return TkwRepository.importCosts(formData)
    }

    static async downloadCostsForm(date: string) {
        const [year, month, day]: string[] = date.split('-')
        const {data} = await TkwRepository.downloadCostsForm({year, month, day})

        const file = new FileService(data);
        file.downloadFile('costs_form');
    }

    static async getForms() {
        if (store.state.form.tkw) {
            return store.state.form.tkw
        }
        const {data} = await TkwRepository.getForms()
        const form = TkwFactory.toFormsDTO(data)
        store.commit('form/setTkwForm', form)

        return form
    }
}

































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ITkwDetailsItem} from '@/types/tkw';
import {copyObject} from '@/utils/universalUtils';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import TkwService from '@/services/TkwService';
import {price, required} from '@/utils/validationRules';
import TkwCostInputs from '@/components/Tkw/Form/TkwCostInputs.vue';

@Component({
  name: 'TkwEditCost',
  components: {TkwCostInputs}
})
export default class TkwEditCost extends Vue {
  @Prop()
  data!: ITkwDetailsItem | null

  form: ITkwDetailsItem = {
    sku: '',
    dateFrom: '',
    currencyValues: {},
    currencyOtherCosts: {},
  }

  currenciesList: string[] = []

  loading: boolean = false
  formLoading: boolean = false

  rules = {
    cost: [price, required]
  }

  @Watch('data')
  onDataChange(value: ITkwDetailsItem) {
    if (value) {
      this.form = copyObject(value)
      this.getForms()
    }
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true
      try {
        await TkwService.saveOrUpdateTkw(this.form)
        this.$emit('updated')
        this.close()
        notify(NotificationTypes.success, this.$t('SYSTEM_TKW_UPDATED'))
      } catch (e) {
        resolveError(e, 'update')
      } finally {
        this.loading = false
      }
    }
  }

  async getForms() {
    try {
      this.formLoading = true
      const {currencyList} = await TkwService.getForms()
      this.currenciesList = [...currencyList]
      currencyList.forEach((currency: string) => {
        //TODO:
        // @ts-ignore
        this.form.currencyValues[currency] = this.form.currencyValues[currency] || undefined
        // @ts-ignore
        this.form.currencyOtherCosts[currency] = this.form.currencyOtherCosts[currency] || undefined
      })
    } catch (e) {
      resolveError(e, 'fetch_data')
    } finally {
      this.formLoading = false
    }
  }

  close() {
    this.$emit('close')
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currencyISO)+" "+_vm._s(_vm.formatCurrency(item.value))+" ")]}},{key:"item.otherCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currencyISO)+" "+_vm._s(_vm.formatCurrency(item.otherCost))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_COST_MORE_INFO'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
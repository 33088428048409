

































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import DebounceInput from '@/components/DebounceInput.vue';
import {ITkwItem} from '@/types/tkw';
import {IDataTablePagination, IPagination} from '@/types/IPagination';
import {DataTableHeader} from 'vuetify';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

@Component({
  name: 'SkuTable',
  components: {DebounceInput}
})
export default class SkuFilters extends Vue {
  @Prop()
  data!: ITkwItem[];
  @Prop()
  params!: IPagination
  @Prop()
  loading!: boolean

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_SKU',
      value: 'sku',
    },
    {
      text: 'SYSTEM_MANUFACTURING_COST',
      value: 'value',
    },
    {
      text: 'SYSTEM_OTHER_COST',
      value: 'otherCost',
    },
    {
      text: '',
      value: 'info',
      sortable: false
    }
  ]

  formatCurrency(num: number) {
    return formatNumberWithSpaces(num, 2)
  }

  @Emit('rowClick')
  onClickRow(row: ITkwItem) {
    return row
  }

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  @Emit('sort')
  sort(value: { sortBy: string[], sortDesc: string[] }) {
    this.params.sortBy = value.sortBy[0];
    this.params.sortDirection = value.sortDesc[0] ? 'DESC' : 'ASC';
    return this.params;
  }

  formatNumber(value: number) {
    return formatNumberWithSpaces(value, 2);
  }
}














import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import DebounceInput from '@/components/DebounceInput.vue';
import {ITkwTableFilters} from '@/types/tkw';

@Component({
  name: 'SkuFilters',
  components: {DebounceInput}
})
export default class SkuFilters extends Vue {
  @Prop()
  filters!: ITkwTableFilters

  @Emit('changed')
  changed() {
    Object.keys(this.filters).forEach((key) => {
      // @ts-ignore
      this.filters[key] = this.filters[key] || '';
    });
    return this.filters;
  }
}

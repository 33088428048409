
















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import TkwForm from '@/components/Tkw/Form/TkwForm.vue';
import TkwImport from '@/components/Tkw/TkwImport.vue';

@Component({
  name: 'TkwCreateModal',
  components: {TkwImport, TkwForm}
})
export default class TkwCreateForm extends Vue {
  @Prop()
  value!: boolean;

  tab: number = 0

  @Emit('input')
  toggleVisibility() {
    return !this.value;
  }

  @Emit('saved')
  onSaved() {
    this.toggleVisibility()
  }
}













































import {Component, Vue} from 'vue-property-decorator';
import SkuFilters from '@/components/Tkw/SkuFilters.vue';
import {ITkwItem, ITkwTableFilters} from '@/types/tkw';
import SkuTable from '@/components/Tkw/SkuTable.vue';
import {IPagination} from '@/types/IPagination';
import TkwService from '@/services/TkwService';
import {resolveError} from '@/utils/notifications';
import TkwCreateModal from '@/components/Tkw/TkwCreateModal.vue';
import TkwDetails from '@/components/Tkw/TkwDetails.vue';
import {copyObject} from '@/utils/universalUtils';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Tkw',
  components: {PageWrap, TkwDetails, TkwCreateModal, SkuTable, SkuFilters}
})
export default class Tkw extends Vue {
  loadingSkuData: boolean = false
  isCreateTkwModalOpened: boolean = false

  skuData: ITkwItem[] = []

  skuTableParams: IPagination = {
    page: 1,
    size: 10,
    totalElements: 0,
  }
  skuFilters: ITkwTableFilters = {
    sku: ''
  }

  selectedTkw: ITkwItem | null = null

  setSelectedTkw(tkw: ITkwItem) {
    this.selectedTkw = tkw
  }

  created() {
    this.getSavedFilters()
    this.fetchSkuData();
  }

  getSavedFilters() {
    const { filters } = getSavedPageData()
    if (filters) {
      this.skuFilters = {...filters}
    }
  }

  openCreateTkwModal() {
    this.isCreateTkwModalOpened = true
  }

  filterSkuData(filters: ITkwTableFilters) {
    this.skuFilters = {...filters}
    savePageData({
      filters
    })
  }

  get filteredSkuData() {
    return this.skuData.filter((item: ITkwItem) => item.sku.includes(this.skuFilters.sku))
  }

  onTkwCreated() {
    this.fetchSkuData()
    if (this.selectedTkw) {
      const tkw = copyObject(this.selectedTkw)
      this.selectedTkw = null
      this.selectedTkw = tkw
    }
  }

  async fetchSkuData(clearSelectedSku?: boolean) {
    this.loadingSkuData = true
    try {
      this.skuData = await TkwService.getAll()
      if (clearSelectedSku) {
        this.selectedTkw = null
      }
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loadingSkuData = false
    }
  }
}










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'Loader'
})
export default class Loader extends Vue{
  @Prop()
  value!: boolean
}















































































import {Component, Vue} from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue';
import {ITkwDetailsItem} from '@/types/tkw';
import TkwService from '@/services/TkwService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {required} from '@/utils/validationRules';
import Loader from '@/components/Loader.vue';
import TkwCostInputs from '@/components/Tkw/Form/TkwCostInputs.vue';

@Component({
  name: 'TkwForm',
  components: {TkwCostInputs, Loader, DatePicker}
})
export default class TkwForm extends Vue {
  loading: boolean = false
  formLoading: boolean = false

  form: ITkwDetailsItem = {
    sku: '',
    dateFrom: '',
    currencyValues: {},
    currencyOtherCosts: {},
  }

  currenciesList: string[] = []

  skuList: string[] = []

  rules = {
    sku: [required],
    dateFrom: [required],
  };

  created() {
    this.getForms()
  }

  closeForm() {
    this.$emit('closeForm')
  }

  async getForms() {
    try {
      this.formLoading = true
      const {skuList, currencyList} = await TkwService.getForms()
      this.skuList = skuList
      this.currenciesList = [...currencyList]
      currencyList.forEach((currency: string) => {
        // @ts-ignore
        this.form.currencyValues[currency] = undefined
        // @ts-ignore
        this.form.currencyOtherCosts[currency] = undefined
      })
    } catch (e) {
      resolveError(e, 'fetch_data')
    } finally {
      this.formLoading = false
    }
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true
      try {
        await TkwService.saveOrUpdateTkw(this.form)
        this.$emit('saved')
        this.closeForm()
        notify(NotificationTypes.success, this.$t('SYSTEM_TKW_SAVED'))
      } catch (e) {
        resolveError(e, 'update')
      } finally {
        this.loading = false
      }
    }
  }
}

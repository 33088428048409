import {IFormsDTO, ITkwItem} from '@/types/tkw';

export default class TkwFactory {
  static toSku(data: any): ITkwItem {
    return {
      currencyISO: data.currencyISO,
      otherCost: data.otherCost,
      sku: data.sku,
      value: data.value,
    }
  }

  static toFormsDTO(data: any): IFormsDTO {
    return {
      currencyList: data.currencyList,
      defaultCurrency: data.defaultCurrency,
      skuList: data.skuList
    }
  }
}
